import React, { useState, useEffect } from "react"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import SEO from "../components/seo"
import { Navigation, Pagination, Scrollbar, A11y } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import "swiper/css/scrollbar"
import {BurgerMenuIconAnimation} from "../constants/layoutStyle"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Zoom from "react-medium-image-zoom"
import "../styles/rmiz-styles.css"
import "../styles/post.scss"
import styled from "styled-components"
import BurgerMenu from "../components/burgerMenu";
import BranchPageLayout from "../components/branchPageLayout";

export default function Post({ pageContext }) {
  const { title, images, lowerData } = pageContext.post
  const description = pageContext.post.description.childMarkdownRemark.html
  const upperData = pageContext.post.upperData.childMarkdownRemark.html

  const UnZoomIconStyle = styled.button`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 4rem;
    height: 4rem;
    background: transparent;
    border: none;
    cursor: pointer;

    div {
      width: 4rem;
      height: 0.1rem;
      background: white;
      border-radius: 5px;
      transform-origin: 1px;
      position: relative;

      :first-child {
        transform: rotate(45deg);
      }

      :nth-child(2) {
        opacity: 0;
      }

      :nth-child(3) {
        transform: rotate(-45deg);
      }
    }
  `
  const UnZoomIcon = props => {
    return (
      <UnZoomIconStyle className="unzoom-icon">
        <div />
        <div />
        <div />
      </UnZoomIconStyle>
    )
  }

  // 操作ブロック領域の定義
  const [block, setBlock] = useState(true)
  const ClickBlocker = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    z-index: ${({ block }) => (block ? 2147483647 : -1)};
    display: ${({ block }) => (block ? "block" : "none")};
  `
  useEffect(() => {
    // 画面ロード後、スライド内部の操作をブロックする
    window.setTimeout(
      () => {
        setBlock(false)
      },
      500, // 仮に0.5秒
      { once: true }
    )
  })

  return (
    <>
      <SEO title={title} description={description} />
      <BranchPageLayout>
        <BurgerMenu iconAnination={BurgerMenuIconAnimation.FadeOut} />
        <div className="post-main">
          <main className="main-area">
            <div className="post-title">
              <h1>{title}</h1>
            </div>
            <div className="post-slidearea">
              <div className="post-swiper-button">
                <div id="button_prev" className="post-swiper-button-prev">
                  ◀
                </div>
                <div className="post-swiper-button-buffer"></div>
                <div id="button_next" className="post-swiper-button-next">
                  ▶
                </div>
              </div>
              <div className="post-slider">
                <Swiper
                  modules={[Navigation, Pagination, Scrollbar, A11y]}
                  spaceBetween={10} // 画像間の隙間の大きさ(px)
                  slidesPerView={1.6} // スライドに表示する
                  navigation={{
                    // パラメータを設定
                    prevEl: "#button_prev",
                    nextEl: "#button_next",
                  }}
                >
                  <ClickBlocker block={block} />
                  {images?.map((image, index) => (
                    <SwiperSlide key={index}>
                      <Zoom zoomMargin={100} IconUnzoom={UnZoomIcon}>
                        <GatsbyImage
                          image={getImage(image)}
                          imgClassName="post-image"
                          alt="post-cover"
                        />
                      </Zoom>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
            <div className="post-textarea">
              <div className="post-data">
                <div
                  dangerouslySetInnerHTML={{ __html: upperData }}
                  className="post-data-upper-data"
                />
                <dl className="post-data-lower-data-dl">
                  {lowerData?.map((elem, index) => (
                    <>
                      <div className="post-data-lower-data" key={index}>
                        <dt className="post-data-lower-data-dt point">
                          {elem.split("|")[0]}
                        </dt>
                        <dd className="post-data-lower-data-dd">
                          {elem.split("|")[1]}
                        </dd>
                      </div>
                    </>
                  ))}
                </dl>
              </div>
              <div dangerouslySetInnerHTML={{ __html: description }} className="post-description" />
            </div>
          </main>
        </div>
      </BranchPageLayout>
    </>
  )
}
